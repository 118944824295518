import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logoImage from "../images/clients/logo.png";

// Import social media icons
import gmailIcon from "../images/clients/email.png";
import facebookIcon from "../images/clients/facebook.png";
import instagramIcon from "../images/clients/instagram.png";
import linkedinIcon from "../images/clients/linkedin.png";

const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="footer max-w-full mx-auto px-4 sm:px-6 border-t border-b py-10">
        <div className="grid grid-cols-1 lg:grid-cols-7 gap-8 py-6 md:py-4">
          {/* 1st column with logo and content, spanning 3 columns on large screens */}
          <div className="lg:col-span-3 flex flex-col items-center lg:items-start">
            <img
              src={logoImage}
              alt="Logo"
              className="  -mt-4 lg:ml-[81px] w-32 h-32"
            />

            <p className="text-center -mt-5  text-lg text-gray-700 font-medium mb-2 lg:ml-20 lg:text-justify lg:w-[420px]">
              An offshore valuation firm specializing in providing comprehensive
              valuation support services, primarily catering to U.S. companies
              with revenues between $50,000 and $50 million.
            </p>
            <p className="text-center text-lg lg:text-left text-gray-600 font-medium lg:ml-20">
              Email:{" "}
              <a
                href="mailto:info@amarvaluations.com"
                className="text-blue-700 hover:underline"
              >
                info@amarvaluations.com
              </a>
            </p>
          </div>

          {/* Remaining columns */}
          <div className="lg:col-span-1 flex flex-col items-center lg:items-start">
            <h6 className="text-[#5D8AA8]dd text-[#013289] text-xl font-bold mb-4">
              LINKS
            </h6>
            <ul className="text-md">
              <li className="mb-2">
                <HashLink
                  to="/#about"
                  className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  About
                </HashLink>
              </li>
              <li className="mb-2">
                <HashLink
                  to="/#services"
                  className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  Services
                </HashLink>
              </li>
              <li className="mb-2">
                <HashLink
                  to="/contact#contact"
                  className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  Contact
                </HashLink>
              </li>
            </ul>
          </div>

          <div className="lg:col-span-1 flex flex-col items-center lg:items-start">
            <h6 className="text-[#013289] text-xl font-bold mb-4 lg:ml-28 lg:h-auto lg:40 w-full text-center">
              OUR SERVICES{" "}
            </h6>
            <ul className="text-md text-center">
              <li className="mb-2">
                <Link
                  to="/GiftEstate"
                  className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  Gift & Estate Tax Valuation
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/BusinessEnterprise"
                  className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  Business Enterprise Valuation
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/Portfolio"
                  className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  Portfolio Valuation
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/IRC409A"
                  className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  IRC 409A Valuation
                </Link>
              </li>
            </ul>
          </div>

          <div className="lg:col-span-1 flex flex-col items-center lg:items-start lg:mt-12 w-full">
            {/* <h6 className="text-[#013289] text-xl font-bold mb-4">OUR SERVICES 2</h6> */}
            <ul className="text-md text-center">
              <li className="mb-2">
                <Link
                  to="/IntangibleAsset"
                  className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  Intangible Asset Valuation
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/FinancialReporting"
                  className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  Financial Reporting Valuation
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/MergersAcquisitions"
                  className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  Mergers & Acquisitions Valuation
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/DueDiligenceSupport"
                  className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                >
                  Due Diligence Support
                </Link>
              </li>
            </ul>
          </div>

          <div className="lg:col-span-1 flex flex-col items-center lg:items-start">
            <h6 className="text-[#013289] text-xl font-bold mb-4">
              Social Media Links
            </h6>
            <p className="text-md font-medium mb-6">
              Follow us on social media.
            </p>
            <ul className="flex justify-center lg:justify-start mb-4 md:mb-0">
              <li className="mr-4">
                <Link
                  to="#"
                  target="_blank"
                  className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                  aria-label="LinkedIn"
                >
                  <img src={linkedinIcon} alt="LinkedIn" className="w-8 h-8" />
                </Link>
              </li>

              <li className="mr-4">
                <Link
                  to=""
                  target="_blank"
                  className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                  aria-label="Facebook"
                >
                  <img src={facebookIcon} alt="Facebook" className="w-8 h-8" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center pt-5">
            <div className="text-lg text-white font-semibold py-1">
              Copyright &copy; {new Date().getFullYear()}
              {"  "}
              <HashLink to="/#home" className="hover:text-gray-900">
                Amar Valuations
              </HashLink>
              . All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
