
import React, { useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import content from "../images/clients/service.jpg";
import ServiceContent from './ServiceContent';
import img from "../images/clients/FinancialReporting.jpg";

const FinancialReporting = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <>
      <NavBar />
      <div className="relative w-full h-[550px] mt-24 overflow-hidden">
        <img 
          src={content} 
          alt="Service" 
          className="w-full h-full object-cover rounded-br-[10%] md:rounded-br-[20%] rounded-bl-[10%] md:rounded-bl-[20%] opacity-80"
        />
        <div className="absolute inset-0 flex items-center justify-center md:justify-end px-4">
          <h1 className="text-5xl font-bold text-white text-center">Financial Reporting Valuation</h1>
        </div>
      </div>
      <div className="container mx-auto px-2 py-8 mt-8">
        <div className="flex w-full flex-col lg:flex-row items-center justify-center">
          <div className="w-full lg:w-1/2">
            <img src={img} alt="Service Image" className="w-full lg:w-3/4 mx-auto md:h-96 h-auto rounded-lg" />
          </div>
          <div className="w-full lg:w-1/2 mt-4 md:mt-0 px-3">
            <h2 className="text-3xl md:text-4xl font-semibold text-gray-800 mb-4 mt-4 text-center lg:text-left">Financial Reporting Valuation</h2>
            <p className="text-gray-600 text-lg mb-4 text-justify">
            Our company specializes in delivering comprehensive valuation solutions tailored specifically for financial reporting purposes. With expertise in fair value measurements, purchase price allocations, and impairment testing, we ensure that our clients remain compliant with accounting standards and regulatory requirements. Our seasoned team employs rigorous methodologies and industry best practices to provide accurate and reliable valuations that withstand the scrutiny of auditors and regulators.  
                <br/>
                <br/>
                Whether you require fair value assessments for complex financial instruments, purchase price allocations for business combinations, or impairment testing for goodwill and intangible assets, our tailored solutions offer the insights you need to make informed financial decisions. Partner with us to ensure transparency, accuracy, and compliance in your financial reporting processes, enabling you to enhance stakeholder confidence and drive business success.
            </p>
          </div>
        </div>
      </div>
      <ServiceContent />
      <Footer />
    </>
  );
};

export default FinancialReporting;
