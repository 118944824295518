
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const NavLinks = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleServicesHover = () => {
    setDropdownOpen(true);
  };

  const handleDropdownLeave = () => {
    // Do nothing to keep the dropdown open
  };

  const handleOutsideClick = () => {
    setDropdownOpen(false);
  };

  return (
    <div
      className="flex  justify-center items-center lg:flex-row flex-col lg:space-y-0 space-y-6 lg:h-24 h-72 lg:space-x-8 mx-auto w-[600px]"
      onClick={handleOutsideClick}
    >
      {/* Increased space between links */}
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        smooth="true"
        to="/#home"
      >
        Home
      </HashLink>
      <Link
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        smooth="true"
        to="/aboutus"
      >
        About
      </Link>
      <div
        className="relative group"
        onMouseEnter={handleServicesHover}
        onMouseLeave={handleDropdownLeave}
      >
        <HashLink
          className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
          smooth="true"
          to="/#services"
        >
          Services <span className=" text-sm">▼</span>
        </HashLink>
        {isDropdownOpen && (
          <div
            className=" absolute bg-white border rounded-lg shadow-lg mt-4 py-2 w-60"
            onMouseLeave={handleOutsideClick}
          >
            <Link
              className="block px-4 py-2 text-gray-500 hover:text-blue-900"
              smooth="true"
              to="/GiftEstate"
            >
              Gift & Estate Tax Valuation
            </Link>
            <Link
              className="block px-4 py-2 text-gray-500 hover:text-blue-900"
              smooth="true"
              to="/BusinessEnterprise"
            >
              Business Enterprise Valuation
            </Link>
            <Link
              className="block px-4 py-2 text-gray-500 hover:text-blue-900"
              smooth="true"
              to="/Portfolio"
            >
              Portfolio Valuation
            </Link>
            <Link
              className="block px-4 py-2 text-gray-500 hover:text-blue-900"
              smooth="true"
              to="/IRC409A"
            >
              IRC 409A Valuation
            </Link>
            <Link
              className="block px-4 py-2 text-gray-500 hover:text-blue-900"
              smooth="true"
              to="/IntangibleAsset"
            >
              Intangible Asset Valuation
            </Link>
            <Link
              className="block px-4 py-2 text-gray-500 hover:text-blue-900"
              smooth="true"
              to="/FinancialReporting"
            >
              Financial Reporting Valuation
            </Link>
            <Link
              className="block px-4 py-2 text-gray-500 hover:text-blue-900"
              smooth="true"
              to="/MergersAcquisitions"
            >
              Mergers and Acquisitions Valuation
            </Link>
            <Link
              className="block px-4 py-2 text-gray-500 hover:text-blue-900"
              smooth="true"
              to="/DueDiligenceSupport"
            >
              Due Diligence Support
            </Link>
          </div>
        )}
      </div>
      <Link
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        smooth="true"
        to="/contact"
      >
        Contact Us
      </Link>
    </div>
  );
};

export default NavLinks;
