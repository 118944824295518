import React, { useEffect } from "react";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar/NavBar";
import img from "../images/clients/BusinessEnterprise.jpg";
import content from "../images/clients/service.jpg";
import ServiceContent from "./ServiceContent";

const BusinessEnterprise = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <>
      <NavBar />
      <div className="relative w-full h-[550px] mt-24 overflow-hidden">
        <img
          src={content}
          alt="Service"
          className="w-full h-full object-cover rounded-br-[10%] rounded-bl-[10%] md:rounded-br-[20%] md:rounded-bl-[20%] opacity-80"
        />
        <div className="absolute inset-0 flex items-center justify-center md:justify-end px-4">
          <h1 className="text-5xl font-bold text-white text-center">
            Business Enterprise Valuation
          </h1>
        </div>
      </div>
      <div className="container mx-auto px-2 py-8 mt-8">
        <div className="flex flex-col w-full lg:flex-row items-center justify-center">
          <div className="w-full lg:w-1/2">
            <img
              src={img}
              alt="Service Image"
              className="w-full lg:w-3/4 mx-auto md:h-96 h-auto rounded-lg"
            />
          </div>
          <div className="w-full lg:w-1/2 mt-4 md:lg-0 px-3">
            <h2 className="text-3xl md:text-4xl lg:text-left text-center font-semibold text-gray-800 mb-4">
              Business Enterprise Valuation
            </h2>
            <p className="text-gray-600 text-lg py-2 mb-4 w-full text-justify">
            Our Business Enterprise Valuation service offers comprehensive valuations tailored to meet your specific needs. By outsourcing to our team of seasoned experts, you gain access to in-depth analysis and insights, considering all relevant factors such as market conditions, financial performance, and industry trends. We ensure compliance with relevant standards and provide detailed, reliable reports that support informed decision-making. Partner with us for trusted Business Enterprise valuation solutions that drive success for your business.
              {/* <br />
              <br />
              We draw on extensive experience and apply relevant valuation
              methodologies. We combine this with our deep sector knowledge and
              benchmarking analysis to offer value-added advice
              <br />
              <br />
              Our valuation report provides an overview of the company, industry
              economy, discusses value drivers; outlines the analysis performed,
              along with the inputs and assumptions; and incorporates detailed
              exhibits that support our valuation conclusion. */}
            </p>
          </div>
        </div>
      </div>
      <ServiceContent />
      <Footer />
    </>
  );
};

export default BusinessEnterprise;
