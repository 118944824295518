import React, { useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import content from "../images/clients/service.jpg";
import ServiceContent from './ServiceContent';
import img from "../images/clients/Portfolio.jpg";

const Portfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <>
      <NavBar />
      <div className="relative w-full h-[550px] mt-24 overflow-hidden">
        <img 
          src={content} 
          alt="Service" 
          className="w-full h-full object-cover rounded-br-[10%] md:rounded-br-[20%] rounded-bl-[10%] md:rounded-bl-[20%] opacity-80"
        />
        <div className="absolute inset-0 flex items-center justify-center md:justify-end px-4">
          <h1 className="text-5xl font-bold text-white text-center">Portfolio Valuation</h1>
        </div>
      </div>
      <div className="container mx-auto px-2 py-8 mt-8">
        <div className="flex flex-col w-full lg:flex-row items-center justify-center">
          <div className="w-full lg:w-1/2">
            <img src={img} alt="Service Image" className="w-full lg:w-3/4 mx-auto md:h-96 h-auto rounded-lg" />
          </div>
          <div className="w-full lg:w-1/2 mt-4 md:mt-0">
            <h2 className="text-3xl md:text-4xl font-semibold text-gray-800 mb-4 mt-4 text-center lg:text-left">Portfolio Valuation</h2>
            <p className="text-gray-600 text-lg mb-4 text-justify px-3">
            Our company specializes in providing comprehensive portfolio valuation services tailored to meet the unique needs of businesses. We employ rigorous methodologies and ensure transparency throughout the valuation process to deliver accurate and reliable fair value measurements of investment holdings and financial instruments. By leveraging our expertise and adherence to regulatory standards, we promote accurate financial reporting and facilitate informed decision-making for our clients.
              <br />
              <br />
              Our services cover a diverse range of assets, including equities, bonds, derivatives, and alternative investments, allowing businesses to gain valuable insights into their investment portfolios. With a commitment to excellence and regulatory compliance, we empower companies to effectively manage their investments, optimize returns, and mitigate risks in today's dynamic market landscape. Partner with us for trusted portfolio valuation solutions that drive success for your business.
            </p>
          </div>
        </div>
      </div>
      <ServiceContent />
      <Footer />
    </>
  );
};

export default Portfolio;
