
import React, { useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import content from "../images/clients/service.jpg";
import ServiceContent from './ServiceContent';
import img from "../images/clients/Mergers&Acuisitions.jpg"

const MergersAcquisitions = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <>
      <NavBar />
      <div className="relative w-full h-[550px] mt-24 overflow-hidden">
        <img 
          src={content} 
          alt="Service" 
          className="w-full h-full object-cover rounded-br-[10%] md:rounded-br-[20%] rounded-bl-[10%] md:rounded-bl-[20%] opacity-80"
        />
        <div className="absolute inset-0 flex items-center justify-center md:justify-end px-4">
          <h1 className="text-5xl font-bold text-white text-center">Mergers and Acquisitions Valuation</h1>
        </div>
      </div>
      <div className="container mx-auto px-2 py-8 mt-8">
        <div className="flex flex-col lg:flex-row items-center justify-center">
          <div className="w-full lg:w-1/2">
            <img src={img} alt="Service Image" className="w-full lg:w-3/4 mx-auto md:h-96 h-auto rounded-lg" />
          </div>
          <div className="w-full lg:w-1/2 mt-4 md:mt-0 px-3">
            <h2 className="text-3xl md:text-4xl font-semibold text-gray-800 mb-4 mt-4 lg:text-left text-center">Mergers and Acquisitions Valuation</h2>
            <p className="text-gray-600 text-lg mb-4 text-justify">
            Our company specializes in providing comprehensive valuation services for mergers and acquisitions, guiding clients through the complexities of evaluating the worth of companies involved in transactional deals. With a team of seasoned experts, we facilitate informed decision-making and drive successful deal outcomes by delivering accurate and insightful valuations. Whether you're considering the acquisition of a target company or seeking to sell your business, our tailored solutions are designed to meet your specific needs and objectives. 
                <br/>
                <br/>
                From assessing the financial health and intrinsic value of target companies to identifying synergies and potential risks, our expertise ensures that you have the insights you need to negotiate effectively and maximize value in M&A transactions. Partner with us to navigate the intricacies of mergers and acquisitions valuation and achieve your strategic objectives with confidence.
            </p>
          </div>
        </div>
      </div>
      <ServiceContent />
      <Footer />
    </>
  );
};

export default MergersAcquisitions;
