import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import emailjs from "emailjs-com";
import Notiflix from "notiflix";
import React, { useState } from "react";

const ServiceContent = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    clearErrors(e.target.name);
  };

  const clearErrors = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.phone) newErrors.phone = "Phone is required";
    if (!formData.message) newErrors.message = "Message is required";
    return newErrors;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    document.getElementById("submitBtn").disabled = true;
    document.getElementById("submitBtn").innerHTML = "Loading...";

    emailjs
      .send(
        "service_4ovuw9d",
        "template_7perxfc",
        formData,
        "pUXhF8B0C7BSL9nvI"
      )
      .then((response) => {
        document.getElementById("submitBtn").disabled = false;
        document.getElementById("submitBtn").innerHTML = "Send Message";
        clearInput();
        Notiflix.Report.success(
          "Success",
          "Your message has been sent successfully.",
          "Okay"
        );
      })
      .catch((error) => {
        document.getElementById("submitBtn").disabled = false;
        document.getElementById("submitBtn").innerHTML = "Send Message";
        Notiflix.Report.failure(
          "An error occurred",
          "Your message could not be sent. Please try again later.",
          "Okay"
        );
      });
  };

  const clearInput = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  return (
    <div className="bg-gray-100 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-center animate__animated animate__fadeIn">
          <div className="w-full md:w-1/2 p-4 md:mx-4 flex flex-col items-start">
            <p className="text-3xl drop-shadow-xl font-bold text-center text-gray-800 mb-4">
              Ready To Discuss Your Business Valuation Needs?
            </p>
            <p className="text-xl text-gray-600 mb-6 text-center md:text-left">
              To discuss your situation and receive information about the
              Business Valuation process, time frame, and cost, please email to{" "}
              <a
                href="mailto:info@amarvaluations.com"
                className="text-blue-500 underline"
              >
                info@amarvaluations.com
              </a>
              , or complete this form and we will get back to you shortly.
            </p>
          </div>
          <div className="w-full md:w-1/2 lg:pl-8 p-4">
            <div className="bg-[#d9e1e8] p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl text-center p-2 font-bold flex items-center justify-center">
                Talk to an Expert
                <FontAwesomeIcon icon={faCommentDots} className="t ml-4" />
              </h2>
              <form onSubmit={sendEmail}>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="border border-gray-400 px-4 py-2 mb-4 w-full rounded-md focus:outline-none focus:border-blue-500"
                />
                {errors.firstName && (
                  <p className="text-red-500 text-sm">{errors.firstName}</p>
                )}
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="border border-gray-400 px-4 py-2 mb-4 w-full rounded-md focus:outline-none focus:border-blue-500"
                />
                {errors.lastName && (
                  <p className="text-red-500 text-sm">{errors.lastName}</p>
                )}
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="border border-gray-400 px-4 py-2 mb-4 w-full rounded-md focus:outline-none focus:border-blue-500"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="border border-gray-400 px-4 py-2 mb-4 w-full rounded-md focus:outline-none focus:border-blue-500"
                />
                {errors.phone && (
                  <p className="text-red-500 text-sm">{errors.phone}</p>
                )}
                <textarea
                  name="message"
                  placeholder="Reason for Valuation"
                  value={formData.message}
                  onChange={handleChange}
                  className="border border-gray-400 px-4 py-2 mb-4 w-full rounded-md focus:outline-none focus:border-blue-500"
                ></textarea>
                {errors.message && (
                  <p className="text-red-500 text-sm">{errors.message}</p>
                )}
                <button
                  type="submit"
                  id="submitBtn"
                  className="bg-[#5D8AA8] text-white px-4 py-2 rounded-md hover:bg-[#385c74] transition duration-300 ease-in-out w-full"
                >
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceContent;
