
import React, { useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import content from "../images/clients/service.jpg";
import ServiceContent from './ServiceContent';
import img from "../images/clients/IntangibleAsset.jpg";

const IntangibleAsset = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <>
      <NavBar />
      <div className="relative w-full h-[550px] mt-24 overflow-hidden">
        <img 
          src={content} 
          alt="Service" 
          className="w-full h-full object-cover rounded-br-[10%] md:rounded-br-[20%] rounded-bl-[10%] md:rounded-bl-[20%] opacity-80"
        />
        <div className="absolute inset-0 flex items-center justify-center md:justify-end px-4">
          <h1 className="text-5xl font-bold text-white text-center">Intangible Asset Valuation</h1>
        </div>
      </div>
      <div className="container mx-auto px-2 py-8 mt-8">
        <div className="flex w-full flex-col lg:flex-row items-center justify-center">
          <div className="w-full lg:w-1/2">
            <img src={img} alt="Service Image" className="w-full lg:w-3/4 mx-auto md:h-96 h-auto rounded-lg" />
          </div>
          <div className="w-full lg:w-1/2 mt-4 md:mt-0 px-3">
            <h2 className="text-3xl md:text-4xl font-semibold text-gray-800 mb-4 ;g:text-left text-center mt-4">Intangible Asset Valuation</h2>
            <p className="text-gray-600 text-lg mb-4 text-justify">
            At our company, we specialize in offering comprehensive intangible asset valuation services tailored to meet the specific needs of businesses. Our experienced team is dedicated to assessing the value of various intangible assets, including intellectual property, trademarks, patents, copyrights, and proprietary technology. Through rigorous analysis and industry expertise, we provide accurate and reliable valuations that enable businesses to make informed strategic decisions regarding asset management, financial reporting, and transactions. 
                <br/>
                <br/>
                Whether you're looking to determine the value of your intellectual property portfolio for financial reporting purposes or seeking to assess the worth of intangible assets in a merger or acquisition, our tailored solutions ensure that you have the insights you need to drive success. Partner with us to unlock the true value of your intangible assets and capitalize on their potential to enhance your business's competitiveness and value proposition.
            </p>
          </div>
        </div>
      </div>
      <ServiceContent />
      <Footer />
    </>
  );
};

export default IntangibleAsset;
