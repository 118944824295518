import React, { useEffect } from "react";
import sideImage from "../images/clients/about.jpg"; // Make sure to add your image file in the appropriate directory
import NavBar from "./Navbar/NavBar";
import Footer from "./Footer";

const AboutUs = () => {
  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <div className="flex flex-col items-center min-h-screen p-6 bg-gray-100 mt-24">
        <div className="flex flex-col lg:flex-row items-center mb-12 lg:w-full w-full lg:h-auto ">
          <div className="lg:w-1/2 w-full md:h-full h-[250px]  md:p-4 p-1 flex items-center justify-center">
            <img
              src={sideImage}
              alt="About Us"
              className="w-full lg:h-[450px] h-full object-cover rounded-lg shadow-lg"
            />
          </div>
          <div className="lg:w-1/2 w-full p-6 rounded-lg flex items-center justify-center h-[70%]">
            <div>
              <h1 className="text-3xl md:text-4xl font-bold mb-4 text-[#5D8AA8] lg:text-left text-center">
                About Amar Valuations
              </h1>
              <p className="text-lg md:text-xl text-gray-600 mb-4 text-justify">
                Amar Valuations is an outsourcing firm specialized in providing
                comprehensive valuation services for businesses across diverse
                industries. Our team is experienced, committed to excellence,
                and aims to deliver valuations that empower informed
                decision-making for organizations.
              </p>
              <p className="text-lg md:text-xl text-gray-600 mb-4 text-justify">
                Our core team comprises highly skilled professionals with
                extensive industry experience. We take pride in leveraging their
                collective expertise and industry best practices to consistently
                deliver quality results to our valued clients. Our dedication
                and proficiency ensure that our clients receive the highest
                level of service and insights to drive their business forward.
              </p>
              <p className="text-lg md:text-xl text-gray-600 text-justify">
                We understand the importance of precise valuations in today's
                dynamic business landscape, and we recognize that organizations
                often seek external expertise to complement their in-house
                capabilities. As a dedicated outsourcing provider, we offer a
                wide range of valuation services tailored to meet the specific
                needs of our clients.
              </p>
            </div>
          </div>
        </div>

        <div className="w-full px-4 py-6 sm:px-6 lg:px-8">
          <div className="rounded-lg w-full mx-auto p-6">
            <h2 className="text-3xl md:text-4xl font-bold mb-8 text-[#5D8AA8] text-center">
              Our Mission, Vision, and Values
            </h2>
            <div className="flex flex-col md:flex-row justify-around mb-8 space-y-6 md:space-y-0">
              <div className="p-6 m-2 flex-1 w-full h-44 rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300 ease-in-out">
                <h3 className="text-2xl font-bold mb-4 text-[#385c74] text-center">
                  Our Mission
                </h3>
                <p className="text-lg text-gray-700 text-center">
                  To deliver high-quality valuation services that empower our
                  clients to make informed business decisions.
                </p>
              </div>
              <div className="p-6 m-2 flex-1 w-full h-44 rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300 ease-in-out">
                <h3 className="text-2xl font-bold mb-4 text-[#385c74]  text-center">
                  Our Vision
                </h3>
                <p className="text-lg text-gray-700 text-center">
                  To be the leading provider of valuation services recognized
                  for our expertise, integrity, and excellence.
                </p>
              </div>
            </div>
            <h2 className="text-2xl font-bold mb-4 text-[#385c74] text-center">
              Values Offer
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {[
                {
                  icon: "verified_user",
                  title: "Integrity",
                  description:
                    "We uphold the highest standards of honesty and ethics in all our dealings.",
                },
                {
                  icon: "group",
                  title: "Team Bonding",
                  description:
                    "We foster strong relationships and collaboration within our team to achieve common goals.",
                },
                {
                  icon: "visibility",
                  title: "Transparency",
                  description:
                    "We believe in transparency and openness in communication, ensuring clarity and trust.",
                },
                {
                  icon: "emoji_objects",
                  title: "Innovation",
                  description:
                    "We embrace innovation as a catalyst for progress, continuously seeking new ways to drive financial growth and efficiency",
                },
                {
                  icon: "star",
                  title: "Excellence",
                  description:
                    "We strive for excellence in everything we do, setting high standards and continually improving.",
                },
                {
                  icon: "work",
                  title: "Client Centric",
                  description:
                    "Our Clients are the heart of everything we do. We are committed to understanding their unique needs",
                },
              ].map((value, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center p-6 bg-[#d9e1e8]  border border-gray-200 rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300 ease-in-out"
                >
                  <span className="material-icons text-4xl text-gray-600 mb-4">
                    {value.icon}
                  </span>
                  <h3 className="text-xl font-bold text-gray-800">
                    {value.title}
                  </h3>
                  <p className="text-gray-700 text-center">
                    {value.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <section className="w-full px-4 py-6 sm:px-6 lg:px-8">
  <div className="max-w-6xl mx-auto p-2 md:p-12">
    <h1 className="text-3xl md:text-4xl font-bold mb-8 text-[#5D8AA8] text-center">
      Why Us?
    </h1>
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
      {/* First row */}
      <div className="flex flex-col items-center text-center lg:text-left">
        <span className="material-icons text-6xl text-gray-600 mb-4">
          build
        </span>
        <h3 className="text-3xl text-[#385c74] font-bold">
          <span className="font-black">Expert Team</span>
        </h3>
        <p className="my-3 text-xl text-gray-600 font-semibold text-justify">
          Our dedicated team of valuation professionals brings extensive experience and specialized knowledge to every engagement, ensuring accurate and reliable results.
        </p>
      </div>
      <div className="flex flex-col items-center text-center lg:text-left">
        <span className="material-icons text-6xl text-gray-600 mb-4">
          attach_money
        </span>
        <h3 className="text-3xl text-[#385c74] font-bold">
          <span className="font-black">Cost Effectiveness</span>
        </h3>
        <p className="my-3 text-xl text-gray-600 font-semibold text-justify">
          By outsourcing your valuation requirements to us, you can save on overhead costs associated with maintaining an in-house valuation team.
        </p>
      </div>
      <div className="flex flex-col items-center text-center lg:text-left">
        <span className="material-icons text-6xl text-gray-600 mb-4">
          trending_up
        </span>
        <h3 className="text-3xl text-[#385c74] font-bold">
          <span className="font-black">Scalability</span>
        </h3>
        <p className="my-3 text-xl text-gray-600 font-semibold text-justify">
          We have the flexibility to scale our resources and services based on your business needs, providing optimal support during peak periods or specific projects.
        </p>
      </div>

      {/* Second row */}
      <div className="flex flex-col items-center text-center lg:text-left">
        <span className="material-icons text-6xl text-gray-600 mb-4">
          verified
        </span>
        <h3 className="text-3xl text-[#385c74] font-bold">
          <span className="font-black"> Quality Assurance</span>
        </h3>
        <p className="my-3 text-xl text-gray-600 font-semibold text-justify">
          We follow robust quality assurance processes to maintain the highest standards of accuracy, compliance, and confidentiality.
        </p>
      </div>
      <div className="flex flex-col items-center text-center lg:text-left">
        <span className="material-icons text-6xl text-gray-600 mb-4">
          schedule
        </span>
        <h3 className="text-3xl text-[#385c74] font-bold">
         <span className="font-black"> Timely Delivery</span>
        </h3>
        <p className="my-3 text-xl text-gray-600 font-semibold text-justify">
          We understand the importance of meeting deadlines. Our commitment to timely delivery ensures that you have the valuation results when you need them.
        </p>
      </div>
      <div className="flex flex-col items-center text-center lg:text-left">
        <span className="material-icons text-6xl text-gray-600 mb-4">
          lock
        </span>
        <h3 className="text-3xl text-[#385c74] font-bold">
          <span className="font-black">Data Security</span>
        </h3>
        <p className="my-3 text-xl text-gray-600 font-semibold text-justify">
        We assured, your data is fortified with cutting-edge encryption and stringent protocols, ensuring utmost confidentiality and peace of mind for your business.
        </p>
      </div>
    </div>
  </div>
</section>

      <Footer />
    </>
  );
};

export default AboutUs;
