import React from "react";
import { Link } from "react-router-dom";
// import img2 from "../images/app.svg";

import img2 from "../images/clients/BusinessEnterprise.jpg";
import img1 from "../images/clients/Gift&Estate.png";
import img3 from "../images/clients/Portfolio.jpg";
import img4 from "../images/clients/IRC409A.jpg";
import img5 from "../images/clients/IntangibleAsset.jpg";
import img6 from "../images/clients/FinancialReporting.jpg";
import img7 from "../images/clients/Mergers&Acuisitions.jpg";
import img8 from "../images/clients/DueDelligenceSupport.jpg";

const Services = () => {
  return (
    <div id="services" className="bg-gray-100 py-6">
      <section data-aos="zoom-in-down">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-[#5D8AA8] uppercase font-bold">
            services
          </h2>

          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold textf-[#385c74] text-gray-700">
            We are deeply committed to the growth and success of our clients.
          </h2>
        </div>

        <div className="px-6" data-aos="fade-down" data-aos-delay="600">
          <div className="grid  sm:grid-cols-2 lg:grid-cols-4 gap-5">
            <Link to="/GiftEstate" className="group">
              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-[#5D8AA8] hover:text-white rounded-lg shadow-2xl p-3 group h-full lg:h-72">
                <div className="m-2 text-justify text-sm ">
                  <div className=" w-full h-44">
                    <img
                      alt="card img"
                      className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-full h-full object-cover"
                      src={img1}
                    />
                  </div>
                  <div className=" mt-4">
                    <h2 className="font-semibold my-4 text-2xl text-center ">
                      Gift & Estate Tax Valuation
                    </h2>
                    {/* <p className="text-md font-medium">
                      It is the process of determining the fair market value of
                      assets transferred during lifetime or through an estate,
                      ensuring compliance with gift and estate tax regulations.
                      It helps establish the appropriate value of assets for tax
                      purposes, facilitating accurate reporting and minimizing
                      potential tax liabilities.
                    </p> */}
                  </div>
                </div>
              </div>
            </Link>

            <Link to="/BusinessEnterprise" className="group">
              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-[#5D8AA8] hover:text-white rounded-lg shadow-2xl p-3 group h-full lg:h-72">
                <div className="m-2 text-justify text-sm">
                  <div className=" w-full h-44">
                    <img
                      alt="card img"
                      className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-full h-full object-cover"
                      src={img2}
                    />
                  </div>
                  <div className=" mt-4">
                    <h2 className="font-semibold my-4 text-2xl text-center ">
                      Business Enterprise Valuation
                    </h2>
                    {/* <p className="text-md font-medium">
                      We determine the fair market value of business,
                      considering key factors such as assets, liabilities,
                      market trends, and financial performance.
                    </p> */}
                  </div>
                </div>
              </div>
            </Link>

            <Link to="/Portfolio" className="group">
              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-[#5D8AA8] hover:text-white rounded-lg shadow-2xl p-3 group h-full  lg:h-72">
                <div className="m-2 text-justify text-sm">
                  <div className=" w-full h-44">
                    <img
                      alt="card img"
                      className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-full h-full object-cover"
                      src={img3}
                    />
                  </div>
                  <div className=" mt-4">
                    <h2 className="font-semibold my-4 text-2xl text-center ">
                      Portfolio Valuation
                    </h2>
                    {/* <p className="text-md font-medium">
                      Determining fair value measurements of investments and
                      financial instruments in a portfolio. It ensures
                      consistent and transparent valuation practices, promoting
                      accurate financial reporting and informed decision-making.
                    </p> */}
                  </div>
                </div>
              </div>
            </Link>

            <Link to="/IRC409A" className="group">
              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-[#5D8AA8] hover:text-white rounded-lg shadow-2xl p-3 group  h-full lg:h-72">
                <div className="m-2 text-justify text-sm">
                  <div className=" w-full h-44">
                    <img
                      alt="card img"
                      className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-full h-full object-cover"
                      src={img4}
                    />
                  </div>
                  <div className=" mt-4">
                    <h2 className="font-semibold my-4 text-2xl text-center ">
                    IRC 409A Valuation
                    </h2>
                    {/* <p className="text-md font-medium">
                    It is the process of determining the fair market value of
                      equity compensation for privately-held companies, ensuring
                      compliance with IRS regulations. It helps establish a
                      defensible valuation for stock options, RSUs, and other
                      equity instruments.
                    </p> */}
                  </div>
                </div>
              </div>
            </Link>

          
            <Link to="/IntangibleAsset" className="group">
              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-[#5D8AA8] hover:text-white rounded-lg shadow-2xl p-3 group h-full lg:h-72">
                <div className="m-2 text-justify text-sm">
                  <div className=" w-full h-44">
                    <img
                      alt="card img"
                      className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-full h-full object-cover"
                      src={img5}
                    />
                  </div>
                  <div className=" mt-4">
                    <h2 className="font-semibold my-4 text-2xl text-center ">
                    Intangible Asset Valuation
                    </h2>
                    {/* <p className="text-md font-medium">
                    Our team assesses the value of intangible assets, including
                    intellectual property, trademarks, patents, copyrights, and
                    proprietary technology.
                    </p> */}
                  </div>
                </div>
              </div>
            </Link>
          
            <Link to="/FinancialReporting" className="group">
              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-[#5D8AA8] hover:text-white rounded-lg shadow-2xl p-3 group h-full lg:h-72">
                <div className="m-2 text-justify text-sm">
                  <div className=" w-full h-44">
                    <img
                      alt="card img"
                      className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-full h-full object-cover"
                      src={img6}
                    />
                  </div>
                  <div className=" mt-4">
                    <h2 className="font-semibold my-4 text-2xl text-center ">
                    Financial Reporting Valuation
                    </h2>
                    {/* <p className="text-md font-medium">
                    We provide valuations for financial reporting purposes, such
                    as fair value measurements, purchase price allocations, and
                    impairment testing, ensuring compliance with accounting
                    standards.
                    </p> */}
                  </div>
                </div>
              </div>
            </Link>

        
            <Link to="/MergersAcquisitions" className="group">
              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-[#5D8AA8] hover:text-white rounded-lg shadow-2xl p-3 group h-full lg:h-72">
                <div className="m-2 text-justify text-sm">
                  <div className=" w-full h-44">
                    <img
                      alt="card img"
                      className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-full h-full object-cover"
                      src={img7}
                    />
                  </div>
                  <div className=" mt-4">
                    <h2 className="font-semibold my-4 text-2xl text-center ">
                    Mergers and Acquisitions Valuation
                    </h2>
                    {/* <p className="text-md font-medium">
                    Our experts evaluate the worth of companies involved in
                    merger and acquisition transactions, facilitating informed
                    decision-making and successful deal outcomes
                    </p> */}
                  </div>
                </div>
              </div>
            </Link>
        
            <Link to="/DueDiligenceSupport" className="group">
              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-[#5D8AA8] hover:text-white rounded-lg shadow-2xl p-3 group h-full lg:h-72">
                <div className="m-2 text-justify text-sm">
                  <div className=" w-full h-44">
                    <img
                      alt="card img"
                      className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-full h-full object-cover"
                      src={img8}
                    />
                  </div>
                  <div className=" mt-4">
                    <h2 className="font-semibold my-4 text-2xl text-center ">
                    Due Diligence Support
                    </h2>
                    {/* <p className="text-md font-medium">
                    We provide tax valuation services tailored to your specific
                    tax planning and compliance needs, delivering precise
                    valuations for estate planning, gift tax, income tax, and
                    transfer pricing purposes.
                    </p> */}
                  </div>
                </div>
              </div>
            </Link>

          
          </div>
        </div>
      </section>

      {/* <section>
        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
          <div
            className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left "
            data-aos="zoom-out"
          >
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center mx-10 w-80 h-[100%]">
              <div className="text-blue-900 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path>
                  <path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path>
                </svg>
              </div>
              <h3
                className="text-3xl  text-blue-900 
                            font-bold"
              >
                We <span className="font-black">Build</span>
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold text-justify">
                  With over 10 years experience in software analysis and design
                  and a deep understanding of the latest IT trends and
                  solutions, we provide customized recommendations and
                  strategies to help you improve your operations, reduce costs,
                  and increase efficiency.
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center mx-10">
              <div className="text-blue-900 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
                </svg>
              </div>
              <h3 className="text-3xl  text-blue-900 font-bold">
                We <span className="font-black">Collaborate</span>
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold text-justify">
                  We can collaborate with your existing tech team to scale
                  existing software applications or design customized software
                  applications that suits your everyday need and simplifies
                  various processes.
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center mx-10">
              <div className="text-blue-900 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
                </svg>
              </div>
              <h3 className="text-3xl  text-blue-900 font-bold">
                We <span className="font-black">Innovate</span>
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold text-justify">
                  We can collaborate with your existing tech team to scale
                  existing software applications or design customized software
                  applications that suits your everyday need and simplifies
                  various processes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Services;
