import React, { useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import content from "../images/clients/service.jpg";
import ServiceContent from './ServiceContent';
import img from "../images/clients/IRC409A.jpg";

const IRC409A = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <>
      <NavBar />
      <div className="relative w-full h-[550px] mt-24 overflow-hidden">
        <img 
          src={content} 
          alt="Service" 
          className="w-full h-full object-cover rounded-br-[10%] md:rounded-br-[20%] rounded-bl-[10%] md:rounded-bl-[20%] opacity-80"
        />
        <div className="absolute inset-0 flex items-center justify-center md:justify-end px-4">
          <h1 className="text-5xl font-bold text-white text-center">IRC 409A Valuation</h1>
        </div>
      </div>
      <div className="container mx-auto px-2 py-8 mt-8">
        <div className="flex flex-col lg:flex-row items-center justify-center">
          <div className="w-full lg:w-1/2">
            <img src={img} alt="Service Image" className="w-full lg:w-3/4 mx-auto md:h-96 h-auto rounded-lg" />
          </div>
          <div className="w-full lg:w-1/2 mt-4 md:mt-0 px-3">
            <h2 className="text-3xl md:text-4xl font-semibold text-gray-800 mb-4 text-center lg:text-left mt-4">IRC 409A Valuation</h2>
            <p className="text-gray-600 text-lg mb-4 text-justify">
            Our company specializes in providing comprehensive IRC 409A valuation services tailored to meet the unique needs of privately-held companies. IRC 409A valuation is a crucial process for determining the fair market value of equity compensation, such as stock options, RSUs, and other equity instruments, ensuring compliance with IRS regulations. Our experienced team utilizes rigorous methodologies and industry best practices to establish defensible valuations that withstand regulatory scrutiny.  
                <br/>
                <br/>
                By partnering with us, businesses can gain peace of mind knowing that their equity compensation plans are in compliance with IRS guidelines, mitigating the risk of costly penalties and legal implications. Our tailored solutions not only ensure regulatory compliance but also provide valuable insights into the true value of equity compensation, empowering companies to attract and retain top talent, incentivize employees, and drive growth. Trust our expertise to navigate the complexities of IRC 409A valuation and unlock the full potential of your equity compensation plans.
            </p>
          </div>
        </div>
      </div>
      <ServiceContent />
      <Footer />
    </>
  );
};

export default IRC409A;
