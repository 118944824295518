
import React, { useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import content from "../images/clients/service.jpg";
import ServiceContent from './ServiceContent';
import img from "../images/clients/DueDelligenceSupport.jpg";

const DueDiligenceSupport = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <>
      <NavBar />
      <div className="relative w-full h-[550px] mt-24 overflow-hidden">
        <img 
          src={content} 
          alt="Service" 
          className="w-full h-full object-cover rounded-br-[10%] md:rounded-br-[20%] rounded-bl-[10%] md:rounded-bl-[20%] opacity-80"
        />
        <div className="absolute inset-0 flex items-center justify-center md:justify-end px-4">
          <h1 className="text-5xl font-bold text-white text-center">Due Diligence Support</h1>
        </div>
      </div>
      <div className="container mx-auto px-2 py-8 mt-8">
        <div className="flex flex-col lg:flex-row items-center justify-center">
          <div className="w-full lg:w-1/2">
            <img src={img} alt="Service Image" className="w-full lg:w-3/4 mx-auto md:h-96 h-auto rounded-lg" />
          </div>
          <div className="w-full lg:w-1/2 mt-4 md:mt-0 px-3">
            <h2 className="text-3xl md:text-4xl font-semibold text-gray-800 mb-4 mt-4 text-center lg:text-left">Due Diligence Support</h2>
            <p className="text-gray-600 text-lg mb-4 text-justify">
            Our company specializes in providing comprehensive due diligence support tailored to meet the unique needs of our clients. With a commitment to excellence and attention to detail, we conduct thorough due diligence assessments to offer expert opinions and insights that support your investment and transactional decisions. Our seasoned team combines industry expertise with rigorous analysis to uncover critical information and potential risks, enabling you to make informed and confident decisions. 
                <br/>
                <br/>
                Whether you're considering an acquisition, investment opportunity, or strategic partnership, our tailored due diligence services provide the insights you need to mitigate risks, identify opportunities, and maximize value. Partner with us to ensure thorough due diligence and strategic decision-making that drives success for your business.
            </p>
          </div>
        </div>
      </div>
      <ServiceContent />
      <Footer />
    </>
  );
};

export default DueDiligenceSupport;
