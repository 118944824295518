import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';

import GiftEstate from './pages/GiftEstate';
import BusinessEnterprise from './pages/BusinessEnterprise';
import Portfolio from './pages/Portfolio';
import IRC409A from './pages/IRC409A';
import IntangibleAsset from './pages/IntangibleAsset';
import FinancialReporting from './pages/FinancialReporting';
import MergersAcquisitions from './pages/MergersAcquisitions';
import DueDiligenceSupport from './pages/DueDiligenceSupport';
import AboutUs from './components/AboutUs';


function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("Amar Valuations");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path='/GiftEstate' element={<GiftEstate/>} />
            <Route path='/BusinessEnterprise' element={<BusinessEnterprise/>} />
            <Route path='/Portfolio' element={<Portfolio/>} />
            <Route path='/IRC409A' element={<IRC409A/>} />
            <Route path='/IntangibleAsset' element={<IntangibleAsset/>} />
            <Route path='/FinancialReporting' element={<FinancialReporting/>} />
            <Route path='/MergersAcquisitions' element={<MergersAcquisitions/>} />
            <Route path='/DueDiligenceSupport' element={<DueDiligenceSupport/>} />
            <Route path="/get-demo" element={<DemoProduct />} /> 
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
