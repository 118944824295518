import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/Navbar/NavBar";
import backgroundImg from "../images/clients/bg.png"; // Replace this with your actual background image path
import { HashLink } from 'react-router-hash-link';

const Hero = () => {
  return (
    <>
      <div className="w-full h-24 overflow-x-hidden bg-white">
        <NavBar />
      </div>
      <div
        className="hero bg-cover bg-center min-h-screen flex flex-col items-center justify-center"
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          backgroundBlendMode: "overlay",
        }}
        id="home"
      >
        <div className="text-center text-white p-4 max-w-4xl mx-auto mt-20">
          <h1 className="mb-5 md:text-5xl text-3xl font-bold text-gray-100">
            Elevate your business potential with expert offshore valuation services
          </h1>
          <p className="text-xl font-semibold tracking-tight mb-5 text-gray-300">
            We're a driven team of valuation experts committed to delivering
            top-tier insights for your business's success.
          </p>
          <HashLink
            smooth to="/#about"
            className="text-white bg-blue-900d bg-[#5D8AA8] hover:bg-[#385c74] inline-flex items-center justify-center px-6 py-3 text-lg shadow-xl rounded-2xl transition ease-in"
          >
            Learn more
            <svg
              className="w-4 h-4 ml-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </HashLink>
        </div>
      </div>
    </>
  );
};

export default Hero;
