import React, { useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import img from "../images/clients/Gift&Estate.png";
import content from "../images/clients/service.jpg";
import ServiceContent from './ServiceContent';

const GiftEstate = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <>
      <NavBar />
      <div className="relative w-full h-[550px] mt-24 overflow-hidden">
        <img 
          src={content} 
          alt="Service" 
          className="w-full h-full object-cover rounded-br-[10%] rounded-bl-[10%] md:rounded-br-[20%] md:rounded-bl-[20%] opacity-80"
        />
        <div className="absolute inset-0 flex items-center justify-center md:justify-end px-4">
          <h1 className="text-5xl font-bold text-white text-center">Gift & Estate Tax Valuation</h1>
        </div>
      </div>
      <div className="container mx-auto px-2 py-8 mt-8">
        <div className="flex w-full flex-col lg:flex-row items-center justify-center">
          <div className="w-full lg:w-1/2">
            <img src={img} alt="Service Image" className="w-full lg:w-3/4 mx-auto md:h-96 h-auto rounded-lg" />
          </div>
          <div className="w-full lg:w-1/2 mt-6 lg:mt-0 px-3">
            <h2 className="text-3xl md:text-4xl font-semibold lg:text-left text-center text-gray-800 mb-4">Gift & Estate Tax Valuation</h2>
            <p className="text-gray-600 text-lg mb-4 text-justify">
              Our esteemed estate and gift tax valuation team specializes in assessing the worth of closely held business interests, securities, and partnerships, offering comprehensive discount studies for various estate planning needs. With a meticulous approach, we provide consulting services tailored to preparing for securities gifting, charitable donations, and crafting effective buy/sell agreements.
              <br />
              <br />
              Valuation reports for estate and gift tax purposes are subject to IRS review, making it crucial that they are conducted by qualified appraisers with expertise in these areas and in adherence to IRS guidelines.  Our valuation experts account for all relevant factors, including the timing of gifting, control and marketability discounts, multiple ownership structures, and appropriate business valuation methodologies. This ensures that your clients receive a comprehensive estate valuation report that complies with IRS standards. Partner with us for trusted Gift & Estate valuation solutions that drive success for your business.
            </p>
          </div>
        </div>
      </div>
      <ServiceContent />
      <Footer />
    </>
  );
};

export default GiftEstate;
